import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Sayfa Bulunamadı' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header="Özür dileriz, böyle bir sayfa yok..."
            subheader='Linki yanlış yazmış olabilirsiniz veya bu sayfa taşınmış olabilir.'
          />
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            Ana Sayfaya Git
          </Button>
        </Section>
      </Main>
    </Stack>
  </>
)
